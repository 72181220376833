<template>
  <div id="error" class="page-404">
    <div class="error-row">
      <div class="error-left">
        <p>{{ $t('Error Code : 404') }}</p>
        <h1 class="lato-medium">{{ $t('Oops!') }}</h1>
        <h2 class="lato-regular">
          {{ $t('We can’t seem to find the page you are looking for') }}
        </h2>
        <nuxt-link :to="localePath('/')" class="btn sf-button">
          {{ $t('Back To Home Page') }}
        </nuxt-link>
      </div>
      <div class="error-right lato-bold">
        {{ $t('4') }}<span>{{ $t('0') }}</span> {{ $t('4') }}
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter, onMounted, useMeta } from '@nuxtjs/composition-api';
import seoHelpers from '~/helpers/seo/seoHelpers';

export default {
  name: 'ErrorLayout',
  // Forcing layout change on page leave.
  layout: 'errorLayout',
  components: {},
  props: {},
  head: {},
  setup() {
    const router = useRouter();
    const { getAlternates, getCanonical } = seoHelpers();
    const cookiebot = process && process.browser && window && window.Cookiebot;

    onMounted(() => {
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
    });

    const pageTitle = 'Error Code: 404';
    useMeta(() => ({
      title: pageTitle,
      link: [...getAlternates(), getCanonical()],
    }));

    return {
      router,
      pageTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@storefront-ui/vue/styles';
@import '@storefront-ui/shared/styles/helpers/_colors.scss';
@import '../assets/style/variables.scss';
#error {
  margin: 0 auto;
  padding: 130px 0;
  max-width: 1110px;
  @include to-mobile-max {
    padding: 60px 0;
  }
  .error-row {
    display: var(--flex);
    align-items: var(--align-items);
    justify-content: space-between;
    @include to-mobile-max {
      flex-direction: var(--flex-col);
      text-align: center;
    }
    .error-left {
      max-width: 36%;
      text-align: left;
      @include to-tablet-max {
        max-width: 45%;
      }
      @include to-mobile-max {
        order: 2;
        max-width: 100%;
        text-align: center;
      }
    }
    .error-right {
      font-size: 290px;
      @include to-tablet-max {
        font-size: 200px;
        line-height: 220px;
      }
      @include to-mobile-max {
        font-size: 130px;
        line-height: 140px;
        text-align: center;
      }
      span {
        color: var(--c-yellow);
        letter-spacing: -56px;
        @include to-tablet-max {
          letter-spacing: -40px;
        }
        @include to-mobile-max {
          letter-spacing: -27px;
        }
      }
    }
  }
  p {
    padding-bottom: 0px;
    color: var(--c-dark-grey);
    font-size: var(--font-size-20);
  }
  h1 {
    padding: 0;
    margin: 0 0 15px 0;
  }
  h2 {
    font-size: var(--font-size-28);
    color: var(--c-checkmark-grey);
    padding-bottom: 50px;
    font-weight: normal;
    margin: 0;
    @include to-mobile-max {
      font-size: var(--font-size-20);
      padding-bottom: 30px;
      line-height: var(--line-height-24);
    }
  }
  .btn {
    padding: 5px 40px;
    height: 61px;
    width: var(--w-auto);
    max-width: max-content;
    display: var(--flex);
    @include to-mobile-max {
      padding: 5px 30px;
      height: 50px;
      margin: auto;
    }
  }
}
</style>
