// eslint-disable-next-line unicorn/filename-case
import Vue from 'vue';
import VueTailwind from 'vue-tailwind';
import { TRichSelect, TDialog } from 'vue-tailwind/dist/components';

const settings = {
  't-dialog': {
    component: TDialog,
    props: {
      fixedClasses: {
        overlay: 'tw-z-200 tw-overflow-auto tw-left-0 tw-top-0 tw-bottom-0 tw-right-0 tw-w-full tw-h-full tw-fixed tw-bg-opacity-50 ',
        wrapper: 'tw-relative tw-mx-auto tw-max-w-lg tw-z-50 tw-bg-white tw-shadow-lg tw-rounded tw-p-4 tw-text-left',
        modal: 'tw-inline-block tw-w-full tw-max-w-md',
        body: 'tw-p-4',
        header: 'tw-p-2 tw-text-lg tw-font-semibold',
        footer: 'tw-p-2 tw-text-right',
        close: 'tw-absolute tw-top-0 tw-right-0 tw-m-3 tw-text-gray-500 hover:tw-text-gray-600',
        buttons: 'tw-mt-4 tw-gap-2 tw-flex tw-justify-center',
        cancelButton: 'tw-p-2 tw-text-sm tw-rounded tw-bg-gray-200 hover:tw-bg-gray-300 tw-flex',
        okButton: 'tw-p-2 tw-text-sm tw-rounded tw-bg-yellow-500 text-white hover:tw-bg-yellow-600 tw-flex',
      },
      classes: {
        overlay: 'tw-bg-black',
        wrapper: '',
        modal: '',
        body: '',
        header: 'tw-border-b tw-pb-2',
        footer: 'tw-border-t tw-pt-2',
        close: '',
      },
      variants: {
        danger: {
          overlay: 'tw-bg-red-100',
          header: 'tw-bg-red-100 tw-text-red-600',
          footer: 'tw-bg-red-100 tw-text-red-600',
        },
        success: {
          overlay: 'tw-bg-green-100',
          header: 'tw-bg-green-100 tw-text-green-600',
          footer: 'tw-bg-green-100 tw-text-green-600',
        },
      },
    },
  },
  't-rich-select': {
    component: TRichSelect,
    props: {
      fixedClasses: {
        wrapper: 'tw-relative',
        buttonWrapper: 'tw-inline-block tw-w-full',
        selectButton: 'tw-flex tw-w-full tw-justify-between tw-items-center tw-p-2 tw-text-left tw-bg-white tw-border tw-shadow-sm tw-border-black',
        selectButtonLabel: 'tw-text-gray-600',
        selectButtonPlaceholder: 'tw-text-gray-400',
        selectButtonIcon: 'tw-fill-current tw-h-4 tw-w-4',
        selectButtonClearButton: 'tw-text-gray-600 hover:tw-text-gray-700',
        dropdown: 'tw-absolute tw-w-full tw-p-2 tw--mt-1 tw-bg-white tw-rounded tw-shadow',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: 'tw-max-h-60 tw-overflow-y-auto tw-bg-white tw-rounded',
        searchWrapper: 'tw-p-2 tw-rounded tw-bg-white',
        searchBox: 'tw-w-full tw-p-2 tw-bg-gray-100 tw-rounded tw-shadow tw-appearance-none',
        optgroup: '',
        option: 'tw-cursor-pointer tw-p-2 hover:tw-bg-yellow-100',
        disabledOption: 'tw-cursor-pointer tw-p-2 tw-text-gray-400 tw-opacity-75',
        highlightedOption: 'tw-cursor-pointer tw-p-2 tw-bg-yellow-100',
        selectedOption: 'tw-cursor-pointer tw-p-2 tw-bg-yellow-100',
        selectedHighlightedOption: 'tw-cursor-pointer tw-p-2 tw-bg-yellow-100',
        optionContent: '',
        optionLabel: '',
        selectedIcon: '',
        removeSelectedIcon: 'tw-fill-current tw-h-4 tw-w-4',
        clearIcon: 'tw-fill-current tw-h-4 tw-w-4',
        dropdownIcon: 'tw-fill-current tw-h-4 tw-w-4',
      },
      classes: {
        wrapper: '',
        buttonWrapper: '',
        selectButton: '',
        selectButtonLabel: '',
        selectButtonPlaceholder: '',
        selectButtonIcon: '',
        selectButtonClearButton: '',
        dropdown: '',
        dropdownFeedback: '',
        loadingMoreResults: '',
        optionsList: '',
        searchWrapper: '',
        searchBox: '',
        optgroup: '',
        option: '',
        disabledOption: '',
        highlightedOption: '',
        selectedOption: '',
        selectedHighlightedOption: '',
        optionContent: '',
        optionLabel: '',
        selectedIcon: '',
        removeSelectedIcon: '',
        clearIcon: '',
        dropdownIcon: '',
      },
    },
  },
};

Vue.use(VueTailwind, settings);
