// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types';
import formatCurrency from '~/helpers/formatCurrency';

interface FormatCurrency {
  $fc(value: number | string): string;
  $fc(value: number | string, options?: Intl.NumberFormatOptions): string;
  $fc(value: number | string, locale?: string, options?: Intl.NumberFormatOptions): string;
}

declare module 'vue/types/vue' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Vue extends FormatCurrency {}
}

declare module '@nuxt/types' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Context extends FormatCurrency {}
}

const plugin: Plugin = (context, inject) => {
  inject('fc', (value: number | string, locale?: string, options = {}): string => {
    // eslint-disable-next-line no-param-reassign
    locale = locale || context.i18n?.localeProperties?.iso.replace('_', '-');
    const numValue: number = typeof value === 'string' ? +value : value;
    if (numValue < 1 && numValue > 0) {
      options = {
        minimumFractionDigits: 4,
        ...options,
      };
    }
    // eslint-disable-next-line no-param-reassign,@typescript-eslint/no-unsafe-assignment
    options = { currency: context.app.$vsf.$gemini.config.state.getCurrency() || context.i18n?.localeProperties?.defaultCurrency, ...options };
    return formatCurrency(numValue, locale, options);
  });
};

export default plugin;
