<template>
  <div>
    <div id="empty-layout">
      <Nuxt />
    </div>
  </div>
</template>

<script>
import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { useTranslation } from '~/composables/';

export default defineComponent({
  name: 'EmptyLayout',
  setup() {
    const { loadTranslations } = useTranslation('translations');
    useFetch(async () => {
      await loadTranslations();
    });

    return {
      ...useTranslation('translations'),
    };
  },
});
</script>

<style lang="scss">
#empty-layout {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
</style>
