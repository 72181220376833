import cookieNames from '~/enums/cookieNameEnum';

const MARKET_GRN = 'grn:channelmanager:market::6511a49ca2b6895296e73fd1';

export default ({ app, $config }) =>
  app.$vsf.$gemini.client.interceptors.request.use((request) => {
    const state = app?.$vsf?.$gemini?.config?.state;
    const appCookies = app?.$cookies;

    if (process.client && state && appCookies) {
      const marketFromState = state.getMarket();
      const acceptLanguageFromState = state.getAcceptLanguage();
      const currencyFromState = state.getCurrency();
      const marketCookie = appCookies.get(cookieNames.marketCookieName);
      const acceptLanguageCookie = appCookies.get(cookieNames.acceptLanguageCookieName);
      const currencyCookie = appCookies.get(cookieNames.currencyCookieName);
      const localeCookie = appCookies.get(cookieNames.localeCookieName);
      const market = $config?.marketGrn || MARKET_GRN;

      const shouldUpdateState =
        !marketFromState ||
        !acceptLanguageFromState ||
        !currencyFromState ||
        !marketCookie ||
        !acceptLanguageCookie ||
        !currencyCookie ||
        !localeCookie;

      if (shouldUpdateState) {
        state.setAcceptLanguage('it-IT');
        state.setMarket(market);
        state.setCurrency('EUR');
        state.setLocale('it');
        appCookies.set(cookieNames.acceptLanguageCookieName, 'it-IT');
        appCookies.set(cookieNames.marketCookieName, market);
        appCookies.set(cookieNames.currencyCookieName, 'EUR');
        appCookies.set(cookieNames.localeCookieName, 'it');
      }
    }
    return request;
  });
